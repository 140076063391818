"use client"
import { ThemeProvider as NextThemesProvider } from "next-themes";
import type { ThemeProviderProps } from "next-themes/dist/types";
import { useEffect, useState } from "react";

export function ThemeProvider({ children, ...props }: ThemeProviderProps) {
  const [mounted, setMounted] = useState(false);

  // Hanya render provider tema setelah mounting di client-side
  // Ini mencegah perbedaan hydration saat menggunakan tema
  useEffect(() => {
    setMounted(true);
  }, []);

  // Render children tanpa tema selama server-side rendering
  // untuk menghindari perbedaan antara server dan client
  if (!mounted) {
    return <>{children}</>;
  }

  return <NextThemesProvider {...props}>{children}</NextThemesProvider>
}