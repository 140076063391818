'use client';

import Script from "next/script";
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';

export default function ConditionalBotpress() {
    const pathname = usePathname();

    // Cek apakah path saat ini adalah halaman muna-assistant
    const isMunaAssistantPage = pathname.includes('/muna-assistant');

    // Fungsi untuk menghapus widget Botpress jika sudah dimuat pada halaman muna-assistant
    useEffect(() => {
        if (isMunaAssistantPage) {
            // Cari dan hapus elemen webchat yang mungkin sudah dimuat
            const removeWebchatElements = () => {
                const webchatRoot = document.getElementById('bp-web-widget-container');
                if (webchatRoot) {
                    webchatRoot.remove();
                }
            };

            // Hapus segera jika ada
            removeWebchatElements();

            // Tambahkan observer untuk menghapus jika dimuat secara asinkron
            const observer = new MutationObserver((mutations) => {
                for (const mutation of mutations) {
                    if (mutation.addedNodes.length) {
                        const webchat = document.getElementById('bp-web-widget-container');
                        if (webchat) {
                            webchat.remove();
                            observer.disconnect();
                            break;
                        }
                    }
                }
            });

            observer.observe(document.body, { childList: true, subtree: true });

            return () => {
                observer.disconnect();
            };
        }
    }, [isMunaAssistantPage]);

    // Jangan render script jika berada di halaman muna-assistant
    if (isMunaAssistantPage) {
        return null;
    }

    // Render script Botpress untuk halaman lain
    return (
        <>
            <Script
                src="https://cdn.botpress.cloud/webchat/v2.2/inject.js"
                strategy="afterInteractive"
                id="botpress-inject"
            />
            <Script
                src="https://files.bpcontent.cloud/2024/10/27/17/20241027172515-5TGZCWLZ.js"
                strategy="afterInteractive"
                id="botpress-config"
                defer
            />
        </>
    );
}